$accentBlue: #2684ff
$darkBlue: #7392b2
$gold: #D8C198

$orange: #F8B796
$red: #AC172D

// Grayscale
$white: #ffffff
$silver: #dddddd
$gray: #aaaaaa
$black: #111111

$background-gray: #F6F8F8

$main-background-color: $background-gray
$main-text-color: $black
$divider-color: rgba($black, .14)

$main-text-size: 14px
$main-border-radius: 4px

$small-space: 16px
$medium-space: 32px
$large-space: 64px

$phone-size: 600px
$tablet-size: 992px
$desktop-size: 1200px

$sidebar-full-width: 240px
$sidebar-collapsed-width: 30px