@import '../../styles/_variables'
@import '../../styles/_mixins'

.chat-user-input-wrapper:has(.chat-user-input):has(.chat-user-input-editable:empty)
    width: 60%

.chat-user-input-wrapper
    max-height: 100px
    min-height: $medium-space*1
    min-width: 500px
    width: 100%
    border: 1px solid $divider-color
    border-radius: $main-border-radius
    overflow: hidden
    transition: width 300ms cubic-bezier(.57,.21,.69,1.25)
    +breakpoint('<phone')
        min-width: unset !important
        width: 100% !important

    .chat-user-input
        position: relative
        max-height: 100%
        width: 100%
        color: $main-text-color
        background-color: $white
        resize: none

        .chat-user-input-editable
            display: block
            padding: $small-space*.8 $small-space
            max-height: 100px
            overflow-y: auto

            &:empty::before
                content: attr(placeholder)
                color: $silver


