@import '../../styles/_variables'
@import '../../styles/_mixins'
    
.table-header
    z-index: 2
    position: absolute
    top: 0
    height: $large-space
    width: 100%
    border-radius: 4px 4px 0 0
    background-image: url('../../resources/images/table_background.jpeg')
    background-position-x: center
    overflow: hidden

    &__blur-box
        z-index: 0
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        backdrop-filter: blur(3px)

    &__content-box
        z-index: 1
        position: relative
        height: 100%
        padding: $small-space
        +flexbox(flex-start, flex-end, row, nowrap)
        gap: $small-space*.6

        .table-head-title
            +flexbox(flex-start, center, row, nowrap)
            gap: $small-space*.6
            text-transform: uppercase
            font-weight: 700
            font-size: 1.1em
            letter-spacing: .6px

            &::before
                display: block
                content: ''
                position: relative
                width: $large-space*2
                height: 2px
                background-color: $black
                background-color: currentColor
                opacity: .14