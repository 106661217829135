@import '../../styles/_variables'
@import '../../styles/_mixins'

.numeric-badge
    flex: 0 0
    +flexbox(center, center, row, nowrap)
    padding: 4px 7px
    border-radius: $small-space
    background-color: rgba($black, .2)
    line-height: 1
    font-weight: 700
    font-size: $main-text-size - 2
    
.text-label
    display: inline-block
    padding: $small-space*.2 $small-space*.4
    border-radius: $main-border-radius
    font-weight: 600
    font-size: .95em

.status-label
    display: inline-block
    padding: $small-space*.2 $small-space*.4
    border-radius: $main-border-radius
    font-weight: 600
    font-size: .95em
    border: 1px solid

    &.active-status-label
        color: #76AA86
        background: rgba(#76AA86, .2)
        border-color: #76AA86

    &.cancelled-status-label
        color: $red
        background: rgba($red, .2)
        border-color: $red

    &.pending-status-label
        color: #313131
        background: rgba(#313131, .2)
        border-color: #313131
