@import './_variables'
@import './_mixins'

*
  box-sizing: border-box
  outline: none

html,
body
  height: 100%
  overflow-x: hidden

body
  position: relative
  margin: 0
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: $main-text-color
  background-color: $main-background-color
  font-size: $main-text-size

body,
button,
textarea
  font-family: 'Mulish', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif

.reset-appearance
  appearance: none
  -moz-appearance: none
  -webkit-appearance: none
  -ms-progress-appearance: none

.display-none
  display: none !important

.normal-text
  font-size: $main-text-size

.medium-text
  font-size: $main-text-size + 2

.lage-text
  font-size: $main-text-size + calc($small-space/4)

.text-white
  color: $white

.text-black
  color: $black

.full-height-page
  min-height: 100vh

.full-width
  width: 100%

.center-box
  +flexbox(center, center, column, nowrap)

.relative
  position: relative
  width: 100%

.main-page-container
  position: relative
  width: calc(100% - $sidebar-full-width)
  min-height: calc(100vh - $large-space)
  left: $sidebar-full-width
  top: $large-space
  padding: $small-space
  transition: width 150ms linear, left 150ms linear

  &.expanded-page-container
    width: calc(100% - $sidebar-collapsed-width)
    left: $sidebar-collapsed-width

.page-header-section
  +flexbox(space-between, center, row, nowrap)
  margin-bottom: $small-space
  width: 100%

  &__left-box,
  &__right-box
    +flexbox(flex-start, center, row, nowrap)
    gap: $small-space

.page-title
  font-size: 1.6em
  line-height: 1
  letter-spacing: .3px
  opacity: .8

  a
    color: inherit

.page-subtitle
  font-size: 1em
  font-weight: 600
  line-height: 1
  letter-spacing: .3px
  opacity: .8
  margin-bottom: $small-space

.page-content-block,
.page-content-flow
  +flexbox(flex-start, flex-start, column, nowrap)
  width: 100%

.page-content-flow
  gap: $medium-space

.elevated-card
  +flexbox(flex-start, flex-start, column, nowrap)
  padding: $small-space
  background: $white
  border-radius: $main-border-radius
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)

.input-form-box
  position: relative
  +flexbox(flex-start, flex-start, row, wrap)
  gap: $small-space*.5
  width: 100%
  max-width: 1400px

  &__row-full-width
    +flexbox(flex-start, flex-start, column, wrap)
    width: 100%

  &__three-col-row,
  &__five-col-row
    display: grid
    gap: $small-space
    row-gap: $small-space*.5
    width: 100%

  &__three-col-row
    grid-template-columns: repeat(3, minmax(0, 1fr))
    +breakpoint('<tablet')
      grid-template-columns: repeat(2, minmax(0, 1fr))
    +breakpoint('<phone')
      grid-template-columns: repeat(1, minmax(0, 1fr))

  &__five-col-row
    grid-template-columns: repeat(5, minmax(0, 1fr))
    +breakpoint('<tablet')
      grid-template-columns: repeat(2, minmax(0, 1fr))
    +breakpoint('<phone')
      grid-template-columns: repeat(1, minmax(0, 1fr))

.horizontal-line-separator
  height: 1px
  background: $divider-color
  position: relative
  width: 100%
  margin: $small-space*.5 0

.chip-container-box
  border: 1px solid $divider-color
  border-radius: $main-border-radius
  padding: $small-space*.6
  width: 100%

  &.not-empty-box
    +flexbox(flex-start, flex-start, row, wrap)
    gap: $small-space*.5
    min-height: auto

  &.empty-box
    +flexbox(center, center, column, wrap)
    min-height: $large-space*2

.lock-screen-box
  z-index: 9
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  background-color: rgba($main-background-color, .6)
  border-radius: $main-border-radius
  cursor: not-allowed

.navigation-paths
  +flexbox(flex-start, center, row, wrap)
  font-size: 1.05em
  line-height: 1
  letter-spacing: .3px
  margin: $small-space 0

  a,
  .navigation-sub-element
    text-decoration: underline
    color: $main-text-color !important
    font-weight: 600

.sub-folders-list
  +flexbox(flex-start, center, row, wrap)
  width: 100%
  gap: $small-space
  margin: $small-space 0

  &__item
    +flexbox(flex-start, center, row, wrap)
    gap: $small-space*.5
    background-color: $gold
    color: $black
    padding: $small-space*.5 $small-space
    border-radius: $main-border-radius

    .sub-folders-list-item-action
      cursor: pointer
      opacity: .5
      transition: opacity 150ms linear

      &:hover
        opacity: 1

    span
      font-weight: 600

// --- Notification dots

.notification-dot-container
  float: right
  display: flex

.no-overlap
  margin-left: 8px
.notification-dot
  +square($small-space*.7)
  border: 1px solid $divider-color
  border-radius: 50%

  &:not(.no-overlap)
    margin-left: -4px

.message-notification
  background-color: #d2bcbf

.result-notification
  background-color: #afd3d3

.device-notification
  background-color: #5c5c5c

.subscription-notification
  background-color: #c8c2d0

.certification-notification
  background-color: #bdc8ca

.faq-notification
  background-color: #e6e6e6

// --- Custom toast messages

.Toastify
  position: absolute

.Toastify__toast
  border-radius: $main-border-radius + 2 !important

.Toastify__close-button
  align-self: auto !important

// --- mui table customizations

.MuiTableCell-head > *
  font-size: .84rem
  letter-spacing: .3px
  font-weight: 600
  color: rgba($black, .84)

.MuiTableRow-root:empty
  height: .1px !important

// -- If a table has empty data in itself this rule is applied
.MuiTableBody-root .MuiTableRow-root:only-child
  height: 100px !important
