@import '../../styles/_variables'
@import '../../styles/_mixins'

.device-chip
    position: relative
    +flexbox(center, center, row, nowrap)
    gap: $small-space*.5
    background: rgba($black, .07)
    border-radius: $main-border-radius
    overflow: hidden

    &__name
        font-weight: 600
        letter-spacing: .3px
        padding: $small-space*.5 0 $small-space*.5 $small-space*.6

    &__remove-icon
        display: inline
        padding: $small-space*.5 $small-space*.7
        height: 100%
        cursor: pointer
        color: rgba($black, .5)
        background-color: transparent
        transition: color 80ms linear, background-color 80ms linear

        &:hover
            color: $red
            background-color: $orange