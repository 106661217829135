@import '../../styles/_variables'
@import '../../styles/_mixins'

.tabs-selector-wrapper
    +flexbox(flex-start, flex-start, row, nowrap)
    gap: $medium-space
    border-bottom: 1px solid $divider-color
    padding: 0 $small-space
    margin: $small-space*.5 0 $small-space
    overflow: hidden

    &__tab-button
        +flexbox(center, center, column, nowrap)
        cursor: pointer
        padding: $small-space*.8 0
        opacity: .5
        position: relative
        transition: opacity 100ms linear

        &::after
            content: ''
            position: absolute
            bottom: 0
            height: 4px
            width: 60%
            border-radius: $small-space $small-space 0 0
            background-color: $black
            transform: translateY(4px)
            transition: transform 100ms linear

        &:hover
            opacity: .65

        &.active
            opacity: 1

        &.active
            &::after
                transform: translateY(0)