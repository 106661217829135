@import './../_variables'
@import './../_mixins'

.auth-form-container
    +flexbox(flex-start, stretch, column, nowrap)
    gap: $small-space
    width: $large-space*5

.auth-page-version-number
    position: absolute
    bottom: $medium-space