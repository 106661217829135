@import '../../styles/_variables'
@import '../../styles/_mixins'

.badge-card-wrapper
    position: relative
    +flexbox(flex-start, flex-end, row, nowrap)

    .remove-circle-icon
        z-index: 1
        cursor: pointer
        position: absolute
        right: $small-space*(-0.5)
        +flexbox(center, center, column, nowrap)
        +square($small-space*1.6)
        border-radius: 50%
        background-color: $red
        color: $white
        top: $small-space*(-0.5)
        
    .blue-card-body
        background: #B6CCE5

    .green-card-body
        background: rgb(195,222,204)
        background: linear-gradient(120deg, rgba(195,222,204,1) 0%, rgba(129,198,151,1) 100%)

    .badge-card-body
        position: relative
        +flexbox(flex-start, center, row, nowrap)
        min-width: $large-space*7
        height: $large-space*2
        border-radius: $main-border-radius
        overflow: hidden

        .badge-avatar-box-shadow
            position: absolute
            top: 0
            left: $small-space*.4
            +square($large-space*2)
            border-radius: 50%
            background: rgba($black, .14)
            transform: scale(1.4)

        .badge-avatar-box
            position: relative
            left: $small-space*(-0.8)
            +flexbox(center, center, column, nowrap)
            +square($large-space*2)
            border-radius: 50%
            transform: scale(1.5)
            background: rgba($black, .34)
            overflow: hidden

            &__image
                position: relative
                height: 100%
                left: $small-space*.8

        .badge-text-box
            +flexbox(flex-start, flex-start, column, nowrap)
            flex: 1
            gap: $small-space*.5
            padding: 0 $small-space 0 $large-space*.7

            .stars-chip
                background-color: rgba($black, .4)
                +flexbox(flex-start, center, row, nowrap)
                gap: $small-space*.3
                padding: $small-space*.3
                border-radius: $main-border-radius
                color: $white
                font-size: .9em
                letter-spacing: .3px
                font-weight: 600

