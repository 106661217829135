@import '../../styles/_variables'
@import '../../styles/_mixins'

.collapsed-navbar
    width: $sidebar-collapsed-width !important

    &:after
        content: ''
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        background: inherit

.main-sidebar
    z-index: 3
    position: fixed
    width: $sidebar-full-width
    background-color: $white
    height: 100%
    border-right: 1px solid $divider-color
    +flexbox(space-between, flex-start, column, nowrap)
    transition: width 150ms linear

    .sidebar-link-container
        +flexbox(flex-start, flex-start, column, nowrap)
        width: 100%
        padding-top: $large-space
        max-height: calc(100% - $large-space*.8)
        overflow-y: auto

        .sidebar-link-box:last-child
            border-bottom: 1px solid $divider-color

        .sidebar-link-box
            width: 100%
            position: relative
            border-top: 1px solid $divider-color

        .sidebar-link
            +flexbox(flex-start, center, row, nowrap)

            &__content
                +flexbox(space-between, center, row, nowrap)
                width: 100%
                background-color: transparent
                text-decoration: none
                color: inherit
                padding: $small-space*.8 $medium-space*.8
                font-size: $main-text-size + 2
                font-weight: 400
                letter-spacing: .3px
                transition: background-color 100ms linear

                &:hover
                    background-color: $divider-color

                &.active
                    pointer-events: none
                    font-weight: 600

                    &::after
                        position: absolute
                        left: 0
                        content: ''
                        height: $small-space*1.4
                        width: $small-space*.3
                        background-color: lighten($black, 15%)
                        border-radius: 0 $small-space $small-space 0

        .sidebar-link-expandable
            +flexbox(flex-start, center, column, nowrap)
            flex-shrink: 0
            height: $medium-space*1.47
            min-height: $medium-space*1.47
            transition: height 250ms ease-out
            // padding: $small-space*.8 $medium-space*.8
            overflow: hidden

            &.expanded
                height: auto
                min-height: fit-content

            &__trigger
                cursor: pointer
                +flexbox(space-between, center, row, nowrap)
                width: 100%
                background-color: transparent
                font-size: $main-text-size + 2
                padding: $small-space*.8 $medium-space*.9 $small-space*.8 $medium-space*.8
                font-weight: 700
                color: rgba($black, .7)
                letter-spacing: .3px
                transition: background-color 100ms linear

                &:hover
                    background-color: $divider-color

                .expand-arrow
                    opacity: .5
                    transform: rotateZ(0)
                    transition: transform 250ms ease-out

                    &.rotated-arrow
                        transform: rotateZ(180deg)

    .collapse-sidebar-trigger
        cursor: pointer
        position: absolute
        bottom: $medium-space*1.15
        right: $small-space*(-1)
        z-index: 2
        +square($medium-space*.9)
        background-color: inherit
        border: 1px solid $divider-color
        border-radius: 50%

        &__icon-box
            opacity: .6
            transform: rotateZ(0)
            transition: transform 250ms ease-out, opacity 150ms linear

            &:hover
                opacity: 1

            &.rotate-collapsed-icon
                transform: rotateZ(180deg)

    .sidebar-bottom-container
        overflow: hidden
        border-top: 1px solid $divider-color
        position: absolute
        bottom: 0
        background-color: inherit
        width: 100%
        padding: $small-space 0 $small-space $small-space*1.8
