@import '../../styles/_variables'
@import '../../styles/_mixins'

.datepicker-wrapper-box
    display: flex !important
    flex-direction: row !important
    align-items: center
    gap: $small-space*.3

.react-datepicker
    display: flex !important
    flex-direction: row !important

.react-datepicker-wrapper
    z-index: 3
    position: absolute
    right: 0
    bottom: 0
    left: 0
    height: 100%

.datepicker-input-trigger
    cursor: pointer
    +flexbox(center, center, column, nowrap)
    padding: $small-space*.5
    border-radius: $main-border-radius
    background: rgba($black, .07)
    +square($medium-space)

.react-datepicker__input-container
    >input
        display: none
        border: none
        position: relative
        width: calc(100% - #{$medium-space})

.react-datepicker-popper
    z-index: 3 !important

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted
    border-radius: 0.3rem
    background-color: $red !important
    color: #fff !important
    font-weight: bold