@import '../../styles/_variables'
@import '../../styles/_mixins'

.main-logo-container
    +flexbox(center, center, column, nowrap)
    width: $large-space*1.4
    height: $medium-space*.8

    img
        position: relative
        width: 100%