@import '../../styles/_variables'
@import '../../styles/_mixins'

.input-error
    border-color: $red !important
    background-color: rgba($red, .1) !important

.text-input-wrapper,
.textarea-input-wrapper
    +flexbox(flex-start, flex-start, column, nowrap)

    &__label-container
        margin: 0 0 $small-space*.3 $small-space*.3

.text-input-component
    width: 100%

.text-input-component,
.textarea-input-component
    +flexbox(flex-start, center, row, nowrap)
    background: $white
    border: 2px solid rgba($black, .14)
    border-radius: $main-border-radius
    transition: border-color 80ms linear

    &:hover
        border-color: rgba($black, .34)

    &:focus-within
        outline-offset: -2px
        outline: 2px solid $accentBlue

    &__input-element
        display: flex
        flex: 2 0 calc(100% - $small-space*1.6)
        border: none
        background: rgba(0,0,0,0)
        font-size: 1.1em
        letter-spacing: .2px
        line-height: 1
        position: relative
        width: 100%
        padding: $small-space*.6 $small-space*.8

        &:disabled
            opacity: .5
            cursor: default
            pointer-events: none

    &__right-icon-container
        flex: 1
        padding-right: $small-space*.8
        color: rgba($black, .6)

        svg
            +square(18px)

// --- Fixed measure

.fixed-measure-input-component
    > .text-input-component
        &__input-element
            flex: 2 0 calc(100% - $medium-space*2)

    &__vertical-separator
        position: relative
        height: 55%
        width: 1px
        margin-right: -1px
        background-color: $divider-color

    &__value-postfix
        cursor: default
        opacity: .5
        font-weight: 700
        height: $medium-space + 7
        padding: 0 $small-space
        min-width: $medium-space + 7

        &:hover
            opacity: .6

// --- Textarea

.textarea-input-wrapper
    width: 100%

.textarea-input-component
    width: 100%
    
    &__input-element
        max-height: 300px
        min-height: 100px
        width: 100%

// --- Select

.select-input-object
    box-shadow: 0 0 0 0 transparent !important

    &:focus-within
        outline-offset: -2px
        outline: 2px solid $accentBlue !important

.select-input-object:focus
    outline: 1px solid $accentBlue !important

// --- Image input

.image-input-box
    position: relative
    overflow: hidden
    cursor: pointer
    +flexbox(center, center, column, nowrap)
    +square($large-space*2.4)
    border: 2px solid rgba($black, .14)
    border-radius: $main-border-radius
    transition: border-color 80ms linear

    &:hover
        border-color: rgba($black, .34)

    &:active,
    &:focus-within
        outline-offset: -2px
        outline: 2px solid $accentBlue

    &__dark-cover
        z-index: 1
        opacity: 0
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        background: rgba($black, .54)
        +flexbox(center, center, column, nowrap)
        transition: opacity 80ms linear
        gap:$small-space

        &:hover
            opacity: 1

        &__icon
            color: $white
            font-size: $main-text-size+6
            cursor: pointer
            opacity: .8
            transition: opacity 80ms linear

            &:hover
                opacity: 1
        
            &.disabled
                opacity: .5
                cursor: default
            
    &__file
        background-color: $white
        +flexbox(center, center, column, nowrap)

    &__audio,
    &__video,
    &__image
        background-color: $darkBlue

    &__file,
    &__audio,
    &__video,
    &__image
        z-index: 0
        position: relative
        height: 100%

    &__hidden-input
        display: none

.file-selector-input-box
    +flexbox(flex-start, center, row, nowrap)
    gap: $small-space*.5

    &__icon-button
        +flexbox(center, center, column, nowrap)
        +square($medium-space)
        padding: $small-space*.5
        border-radius: $main-border-radius
        cursor: pointer
        background: rgba($black, .07)

        &:hover
            background: rgba($black, .14)

    &__filename
        font-weight: 600