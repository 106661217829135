@import '../../styles/_variables'
@import '../../styles/_mixins'

.custom-link-element,
.custom-link-element:active,
.custom-link-element:visited
    color: $black

.custom-link-element
    cursor: pointer
    font-weight: 400
    text-decoration: underline
    