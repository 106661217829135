@import '../../styles/_variables'
@import '../../styles/_mixins'

.disabled-checkbox
    opacity: .6

    & label, & input[type=checkbox], & label:before, & label:after
        cursor: not-allowed !important

.md-checkbox
    position: relative
    margin: 1em 0
    text-align: left

    &.md-checkbox-inline
        display: inline-block

    & label
        cursor: pointer
        display: inline
        line-height: 1.5
        clear: both
        padding-left: 1px
        font-weight: 600
        letter-spacing: .3px

        &:hover
            &::before
                border: 2px solid rgba($black, 0.34) !important

    & label:not(:empty)
        padding-left: 0.75em

    & label:before, & label:after
        content: ""
        position: absolute
        left: 0
        top: 0

    & label:before
        width: 1.25em
        height: 1.25em
        background: $white
        border: 2px solid rgba($black, 0.24)
        border-radius: $main-border-radius
        cursor: pointer
        transition: background 100ms

    & input[type=checkbox]
        outline: 0
        visibility: hidden
        width: 1.25em
        margin: 0
        display: block
        float: left
        font-size: inherit

    & input[type=checkbox]:checked + label:before
        background: $accentBlue
        border: 2px solid transparent

    & input[type=checkbox]:checked + label:after
        transform: translate(0.3em, 0.45em) rotate(-45deg)
        width: 0.75em
        height: 0.25em
        border: 0.155em solid $white
        border-top-style: none
        border-right-style: none

    & input[type=checkbox]:disabled + label:before
        border-color: rgba($black, 0.14)

    & input[type=checkbox]:disabled:checked + label:before
        background: rgba($black, 0.14)
