@import '../../styles/_variables'
@import '../../styles/_mixins'

.chat-message-list
    position: relative
    // padding: 0 $medium-space $large-space*1.68
    // max-height: calc(100vh - 60px)
    overflow: hidden

    &__time-section
        +flexbox(center, center, column, nowrap)

        &__header-chip
            font-size: .84em
            background-color: #D3DBDB
            color: $black
            line-height: 110%
            font-weight: 600
            letter-spacing: .3px
            padding: $small-space*.22 $small-space*.44
            border-radius: $main-border-radius*4
            margin: $small-space 0

        &__messages-container
            +flexbox(flex-start, flex-start, column, nowrap)
            width: 100%
            max-width: 1400px