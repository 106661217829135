@import '../../styles/_variables'
@import '../../styles/_mixins'
@import '../../styles/_animations'

.button
    cursor: pointer
    position: relative
    border: none
    background: none
    font-size: $main-text-size
    line-height: 1
    +flexbox(center, center, row, nowrap)
    padding: $small-space*.8 $small-space
    border-radius: $main-border-radius
    font-weight: 700
    letter-spacing: .2px
    opacity: 1
    overflow: hidden
    border: 2px solid transparent
    transition: opacity 150ms linear

    &:disabled
        cursor: not-allowed !important
        opacity: .6 !important

    &__absolute-loading-wrapper
        position: absolute
        top: 0
        bottom: 0
        right: 0
        left: 0
        z-index: 2
        background: inherit

.primary--variant
    color: $white
    background: rgb(102,105,110)
    background: linear-gradient(140deg, rgba(102,105,110,1) 0%, rgba(54,54,54,1) 100%)
    border-color: rgb(102,105,110)

.secondary--variant
    color: $black
    border-color: rgba($divider-color, .4)

.secondary-error--variant
    color: $red
    border-color: rgba($red, .4)

.ghost--variant
    transition: background 80ms linear

    &.active,
    &:hover
        background: $divider-color