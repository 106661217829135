@import '../../styles/_variables'
@import '../../styles/_mixins'

.main-navbar
    z-index: 999
    +flexbox(space-between, center, row, nowrap)
    background-color: $white
    padding: $small-space $small-space*1.6
    position: fixed
    left: 0
    right: 0
    border-bottom: 1px solid $divider-color

    // .left-section

    .right-section
        position: relative
        +flexbox(flex-start, center, row, nowrap)
        gap: $small-space

        .navbar-widget-wrapper
            position: relative

            .action-icon
                cursor: pointer
                opacity: .6

                &.active,
                &:hover
                    opacity: 1
                    transition: opacity 100ms linear

.action-panel
    z-index: 4
    position: absolute
    top: $medium-space
    right: $small-space*(-0.5)
    background: $white
    border-radius: $main-border-radius
    border: 1px solid $divider-color
    +flexbox(flex-start, flex-start, column, nowrap)
    box-shadow: 0px 6px 15px -3px rgba(0,0,0,0.1)

.user-profile-panel
    gap: $small-space*.5
    min-width: $large-space*3

    &__upper-section
        +flexbox(flex-start, center, row, nowrap)
        gap: $small-space
        padding: $small-space*.8
        border-bottom: 1px solid rgba($divider-color, .07)

        &__profile-avatar
            +flexbox(center, center, column, nowrap)
            +square($medium-space*1.2)
            background-color: $orange
            border-radius: 50%
            font-size: 1.3em
            line-height: 1
            font-weight: 700

        &__profile-info
            +flexbox(flex-start, flex-start, column, nowrap)

            > .user-name
                font-weight: 600
                font-size: 1.1em

            > .user-email
                letter-spacing: .2px
                font-size: .94em

    &__lower-section
        +flexbox(flex-start, flex-start, column, nowrap)
        width: 100%
        padding-bottom: $small-space*.6

        &__option
            cursor: pointer
            width: 100%
            letter-spacing: .2px
            background-color: transparent
            padding: $small-space*.5 $small-space
            transition: background-color 80ms linear

            &:hover
                background-color: rgba($black, .07)

// Notifications style

.notifications-flow
    width: $large-space*4
    max-height: $large-space*5
    overflow-y: auto
    scrollbar-gutter: stable

    .notifications-section-title
        text-transform: uppercase
        font-weight: 700
        font-size: .70em
        letter-spacing: .3px
        opacity: .8
        padding: $small-space*.5

    .notifications-section-flow
        +flexbox(flex-start, flex-start, column, nowrap)
        width: 100%

        &__notification-box
            cursor: pointer
            +flexbox(flex-start, flex-start, row, nowrap)
            gap: $small-space
            width: 100%
            padding: $small-space
            background-color: transparent
            transition: background-color 80ms linear

            &:hover
                background-color: rgba($black, .07)

            &__dot-container
                +square($small-space*.5)

                .notification-dot
                    margin-top: $small-space*.2

            &__message-container
                line-height: 1.1

    .empty-notifications-list
        width: 100%
        +flexbox(center, center, column, nowrap)
        padding: $medium-space $small-space*.5
