@import '../../styles/_variables'
@import '../../styles/_mixins'

.modal-wrapper
    z-index: 9999
    +flexbox(center, center, column, nowrap)
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0

    .dark-cover
        z-index: 0
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        background: rgba($black, .24)

    .modal-component
        z-index: 1
        height: auto
        overflow: hidden
        border-radius: $main-border-radius
        background: $white
        padding: $small-space
        +flexbox(flex-start, flex-start, column, nowrap)
        gap: $small-space
        width: 95%
        max-width: 400px

        &__header,
        &__body,
        &__footer
            position: relative
            width: 100%

        &__header,
        &__footer
            display: flex
            flex-direction: row
            flex-wrap: nowrap
            gap: $small-space
        
        &__footer
            justify-content: flex-end
            align-items: center

.translation-modal-component
    z-index: 1
    position: relative
    height: auto
    overflow: auto
    border-radius: $main-border-radius
    background: $white
    padding: $small-space
    +flexbox(flex-start, flex-start, column, nowrap)
    gap: $medium-space
    width: 95%
    max-height: 100%
    max-width: $tablet-size
    margin: $small-space

    &__body
        max-height: 600px
        overflow: auto
        width: 100%
        scrollbar-gutter: stable both-edges

        .translations-container
            +flexbox(flex-start, flex-start, row, wrap)
            gap: $small-space
            padding: 0 $small-space $small-space

            &__translation-box
                +flexbox(flex-start, stretch, column, nowrap)
                gap: $small-space
                width: calc(50% - $small-space*.5)

                &__header
                    +flexbox(flex-start, center, row, nowrap)
                    gap: $small-space

                &__fields-container
                    +flexbox(flex-start, stretch, column, nowrap)
                    gap: $small-space*.5

            .default-language-box
                width: 100% !important
                border-bottom: 1px solid $divider-color
                margin-bottom: $small-space
                padding-bottom: $small-space

    &__header,
    &__body,
    &__footer
        position: relative
        width: 100%

    &__header,
    &__footer
        display: flex
        flex-direction: row
        flex-wrap: nowrap
        gap: $small-space
    
    &__footer
        background-color: $white
        justify-content: flex-end
        align-items: center

.media-modal
    max-width: 900px !important
    max-height: 80%

    .modal-media-player-container
        +flexbox(center, center, column, nowrap)
        height: 100%
        height: 600px