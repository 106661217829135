@import './../_variables'
@import './../_mixins'

.entity-avatar-wrapper
    +flexbox(flex-start, flex-end, row, nowrap)
    gap: $small-space

    &__avatar-box
        +square($large-space*2.4)
        +flexbox(center, center, column, nowrap)

    &__text-box
        +flexbox(flex-start, flex-start, column, nowrap)
        flex: 1
        gap: $small-space*.5

.single-language-box
    +flexbox(flex-start, flex-start, column, nowrap)
    padding: $small-space
    flex: 1
    gap: $small-space*.5

.text-paragraphs-container
    +flexbox(flex-start, flex-start, column, nowrap)
    flex: 1
    gap: $small-space*.5
    margin: $small-space 0
    font-size: 1.1em
    font-weight: 600

.items-row
    +flexbox(flex-start, center, row, nowrap)
    gap: $small-space
