@import '../../styles/_variables'
@import '../../styles/_mixins'

.chat-panel-wrapper
    position: relative
    height: calc(100vh - 220px)
    overflow: hidden

    .remove-left-margin
        margin-left: 25px

    .add-left-margin
        margin-left: 250px

    .chat-input-section
        position: fixed
        bottom: $small-space*3
        left: 0
        right: 0
        // height: 60px
        max-height: 100px
        // background-color: $main-background-color
        +flexbox(center, center, row, nowrap)
        visibility: hidden
        transition: margin-left 80ms linear
        +breakpoint('<phone')
            bottom: $small-space

        &__inner
            visibility: visible
            +flexbox(center, center, row, nowrap)
            flex: 1
            width: 100%
            max-width: 1500px
            margin: 0 $medium-space
            +breakpoint('<phone')
                margin: 0 $small-space

            &__button--primary
                transition: background-color 120ms linear
                &:hover
                    background-color: $red // prev -> $blue

            &__button
                cursor: pointer
                position: relative
                +flexbox(center, center, row, nowrap)
                background-color: transparentize($black, .3)
                height: $small-space
                border-radius: 50%
                transition: border-color 120ms linear

                &:hover
                    border-color: $divider-color

.active-context-feature
    background-color: $black !important
    color: $orange // prev -> $fuchsia

.disabled-chat-input-button
    cursor: not-allowed
    opacity: .8
    pointer-events: none
