@import '../../styles/_variables'
@import '../../styles/_mixins'

.advanced-search-panel
    z-index: 10
    +flexbox(space-between, flex-start, row, nowrap)
    gap: $small-space
    background-color: $white
    border-radius: $main-border-radius
    border: 1px solid $divider-color
    margin-bottom: $small-space*.5
    padding: $small-space
    width: 100%
    +breakpoint('<tablet')
        flex-direction: column

    &__params-container
        +flexbox(flex-start, stretch, row, wrap)
        width: 100%
        max-width: $desktop-size

    &__actions-container
        margin-top: $medium-space*.64
        +flexbox(flex-end, center, row, nowrap)
        gap: $small-space
        +breakpoint('<tablet')
            width: 100%
            align-items: flex-end